import { AxiosInstance } from "axios";

interface CommentStoreResponse {
  filename: string;
  status: string;
}

interface CommentCheckResponse {
  exists: boolean;
  filename: string;
  error?: string;
}

interface CommentUpdateResponse {
    message: string;
    old_filename: string;
    new_filename: string;
}

export interface CommentItem {
  id: string;
  filename: string;
  target_node_ids: string[];
  inserted_at: string;
  content: string;
  tags: {
    'Target-1': string;
    IsComment: string;
    [key: string]: string;
  };
  organization_id: string;
}

export const calculateNewResponse = async (api: AxiosInstance, org_slug: string, query: string, comment: string, show_documents: boolean = false) => {
  try {
    const response = await api.post(`/${org_slug}/rag/comment/calculate`, {
      query,
      comment,
      show_documents
    });

    return response.data;
  } catch (error) {
    console.error('Error calculating new response:', error);
    throw error;
  }
}

export const storeComment = async (api: AxiosInstance, org_slug: string, comment: string, query: string): Promise<CommentStoreResponse> => {
  const response = await api.post(`/${org_slug}/rag/comment/store`, {
    comment,
    query
  });
  return response.data;
}

export const checkComment = async (api: AxiosInstance, org_slug: string, filename: string): Promise<CommentCheckResponse> => {
  const response = await api.post(`/${org_slug}/rag/comment/check`, {
    filename,
  });
  return response.data;
}

export const listComments = async (api: AxiosInstance, org_slug: string, query: string): Promise<CommentItem[]> => {
  const response = await api.post(`/${org_slug}/rag/comment/list`, {
    query
  });
  return response.data.comments;
};

export const deleteComment = async (api: AxiosInstance, org_slug: string, commentId: string): Promise<void> => {
  await api.delete(`/${org_slug}/rag/comment/${commentId}`);
};

export const updateComment = async (api: AxiosInstance, org_slug: string, commentId: string, query: string, newComment: string): Promise<CommentUpdateResponse> => {
  const response = await api.put(`/${org_slug}/rag/comment/${commentId}`, {
    comment: newComment,
    query: query
  });
  return response.data;
};