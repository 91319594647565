// auth.js
import { AxiosInstance } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { BASE_URL } from 'src/config';

export const checkUserStatus = async (input: any) => {
  let requestBody = {};
  if (input.userId && input.passphrase && input.orgSlug) {
    // Passphrase case
    requestBody = {
      user_id: input.userId,
      passphrase: input.passphrase,
      org_slug: input.orgSlug,
    };
    console.log(`[${input.orgSlug}] Request body for checkUserStatus:`, requestBody);
  } else {
    // Other cases (e.g., demo user) are pending implementation
    return { status: 'active' };
  }

  const response = await fetch(`${BASE_URL}/${input.orgSlug}/auth/check_user_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('User not found');
    } else if (response.status === 403) {
      throw new Error('User account not active or expired');
    } else {
      throw new Error('Failed to check user status');
    }
  }

  return await response.json();
};

export const getSessionToken = async (org_slug: string) => {
  const token = localStorage.getItem(`${org_slug}_sessionToken`);
  if (!token) {
    throw new Error('Session token not found');
  }

  return token;
}

export const getUserId = async (org_slug: string) => {
  const userId = localStorage.getItem(`${org_slug}_userId`);
  if (!userId) {
    throw new Error('User ID not found');
  }

  return userId;
}

export const clearSessionData = (org_slug: string) => {
  localStorage.removeItem(`${org_slug}_sessionToken`);
  localStorage.removeItem(`${org_slug}_userId`);
  localStorage.removeItem(`${org_slug}_passphrase`);
  localStorage.removeItem(`${org_slug}_phone`);
}

export const setSessionData = ({ org_slug, sessionToken, userId, passphrase, phone }: { org_slug: string, sessionToken: string, userId: string, passphrase?: string; phone?: string }) => {
  localStorage.setItem(`${org_slug}_sessionToken`, sessionToken);
  localStorage.setItem(`${org_slug}_userId`, userId);
  if(passphrase) {
    localStorage.setItem(`${org_slug}_passphrase`, passphrase);
  }
  if(phone) {
    localStorage.setItem(`${org_slug}_phone`, phone);
  }
}

// Check if token is valid and exists in local storage
export const isTokenValid = (org_slug: string) => {
  const token = localStorage.getItem(`${org_slug}_sessionToken`);
  
  if(token) {
    const decodedToken = jwtDecode(token);

    if(!decodedToken) {
      return false;
    }

    if(!decodedToken.exp) {
      return false;
    }

    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  }

  return false;
}

// Get the method of auth for an org
export const getAuthMethod = async (org_slug: string) => {
  const response = await fetch(`${BASE_URL}/${org_slug}/auth/method`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get auth method');
  }

  return await response.json();
}

export class PassphraseFailException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PassphraseFailException';
  }
}

export class AccountInactiveException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AccountInactiveException';
  }
}

// Login with passphrase
export const loginWithPassphrase = async (org_slug: string, passphrase: string) => {
  try {
    const response = await fetch(`${BASE_URL}/${org_slug}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth_method: 'PP', passphrase }),
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new AccountInactiveException('User account not active or expired');
      } else {
        throw new PassphraseFailException('Failed to login with passphrase');
      }
    }

    const data = await response.json();

    if(data.access_token) {
      setSessionData({ org_slug, sessionToken: data.access_token, userId: data.user_id, passphrase });
      return data;
    } else {
      throw new Error('Failed to login with passphrase');
    }
  } catch (e) {
    throw e;
  }
}

// Login with password
export const loginWithPassword = async (org_slug: string, identifier: string, password: string, identifierType: 'email' | 'phone' = 'email') => {
  const response = await fetch(`${BASE_URL}/${org_slug}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      auth_method: 'PW', 
      [identifierType]: identifier, 
      password 
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to login with password');
  }

  const data = await response.json();

  if(data.access_token) {
    setSessionData({ org_slug, sessionToken: data.access_token, userId: data.user_id });
    return data;
  } else {
    throw new Error('Failed to login with password');
  }
}

// Get yourself
export const getSelf = async (api: AxiosInstance, org_slug: string) => {
  try {
    const response = await api.get(`/users/me`);

    return response.data;
  } catch (error) {
    console.error('Error getting self:', error);
    return null;
  }
}

export class PhoneNotFoundException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PhoneNotFoundException';
  }
}

// Supply phone number for Twilio auth
export const supplyPhoneNumber = async (org_slug: string, phone_number: string) => {
  const response = await fetch(`${BASE_URL}/${org_slug}/auth/verification-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ phone_number }),
  });

  if(response.status === 404) {
    throw new PhoneNotFoundException('Phone number not found');
  }

  if (!response.ok) {
    throw new Error('Failed to supply phone number');
  }

  return await response.json();
}

// Submit Twilio verification code
export const submitTwilioVerification = async (org_slug: string, phone_number: string, code: string) => {
  const response = await fetch(`${BASE_URL}/${org_slug}/auth/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ phone_number, code }),
  });

  if (!response.ok) {
    throw new Error('Failed to verify Twilio code');
  }

  const data = await response.json();

  if(data.access_token) {
    setSessionData({ org_slug, sessionToken: data.access_token, userId: data.user_id, phone: phone_number });
    return data;
  } else {
    throw new Error('Failed to verify Twilio code');
  }
}

// Login as guest
export const loginAsGuest = async (org_slug: string) => {
  const response = await fetch(`${BASE_URL}/${org_slug}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ auth_method: 'GUEST' }),
  });

  if (!response.ok) {
    throw new Error('Failed to login as guest');
  }

  const data = await response.json();

  if(data.access_token) {
    setSessionData({ 
      org_slug, 
      sessionToken: data.access_token, 
      userId: data.user_id 
    });
    return data;
  } else {
    throw new Error('Failed to login as guest');
  }
}