import React, { useContext, createContext, useEffect } from "react";
import { useApi } from "src/api/api";
import { getTotalUnread } from "src/api/messaging";
import { useOrgContext } from "./org-context";

const MessagingContext = createContext({
  unread: 0,
  setUnread: (_: number) => {},
  fetchUnread: (_: any) => {},
});

export const useMessaging = () => {
  return useContext(MessagingContext);
};

const MessageStateUpdater = ({ org_slug }: { org_slug: string }) => {
  const api = useApi(org_slug);
  const { setUnread, fetchUnread } = useMessaging();

  useEffect(() => {
    // Check if on the login page
    if (window.location.pathname.includes("/login")) {
      return;
    } else {
      if (typeof api === "undefined") return;

      fetchUnread(api);

      // Schedule a fetch every 10 seconds
      setInterval(fetchUnread, 10000);
    }
  }, [api, setUnread, fetchUnread]);

  return null;
};

export const MessagingProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [unread, setUnread] = React.useState(0);
  const { org_slug, is_guest } = useOrgContext();

  const fetchUnread = async (api: any) => {
    if (typeof api === "undefined") return;
    try {
      const unread = await getTotalUnread(api);
      setUnread(unread);
    } catch (e) {
      console.error("Failed to fetch unread messages", e);
    }
  };

  return (
    <MessagingContext.Provider value={{ unread, setUnread, fetchUnread }}>
      {org_slug && !is_guest && <MessageStateUpdater org_slug={org_slug} />}
      {children}
    </MessagingContext.Provider>
  );
};
